import React, { FormEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../store'
import TodosList from '../components/TodosList'
import { todosActions } from '../store'
import { Todo, TodosState } from '../store/types'

const TodosPage = () => {
  const dispatch = useDispatch()
  const todosState = useSelector<RootState, TodosState>((state) => state.todos)

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const textInput: HTMLInputElement = (event.target as any).elements.text
    const text = textInput.value

    if (text) {
      dispatch(todosActions.createTodo(text))
      textInput.value = ''
    }
  }

  const handleCompleteToggle = (todo: Todo) => {
    dispatch(todosActions.toggleCompletedStatus(todo))
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <input type="text" name="text" placeholder="Add new todo" />
        <button type="submit">Add</button>
      </Form>
      <TodosList
        todos={todosState.data}
        onCompleteToggle={handleCompleteToggle}
      />
    </Container>
  )
}

export default TodosPage

const Container = styled.div`
  max-width: 400px;
  padding: 10px;
  margin: 0 auto;
`

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
