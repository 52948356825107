import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Todo, TodosState } from './types'

const initialState: TodosState = {
  data: [],
  isFetching: false,
  error: null,
}

const todosSlice = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    createTodo: {
      reducer: (state, action: PayloadAction<Todo>) => {
        state.isFetching = false
        state.data.unshift(action.payload)
      },
      prepare: (text: string) => {
        const id = Math.floor(Math.random() * 10000)
        const payload = { id, text, completed: false }

        return { payload }
      },
    },
    toggleCompletedStatus: (state, action: PayloadAction<Todo>) => {
      const todo = state.data.find((it) => it.id === action.payload.id)
      if (todo) {
        todo.completed = !todo.completed
      }
    },
  },
})

export default todosSlice
