import React from 'react'
import { Switch, Route } from 'react-router-dom'
import DashboardPage from '../modules/dashboard/pages/DashboardPage'
import LoginPage from '../modules/auth/pages/LoginPage'
import TodosPage from '../modules/todos/pages/TodosPage'

const Routes = () => {
  return (
    <Switch>
      <Route path="/login">
        <LoginPage />
      </Route>
      <Route path="/dashboard">
        <DashboardPage />
      </Route>
      <Route path="/todos">
        <TodosPage />
      </Route>
    </Switch>
  )
}

export default Routes
