import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import reducer from './reducers'

export type RootState = ReturnType<typeof reducer>

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware(),
})

export default store
