import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Box from '../../elements/Box'
import Routes from '../Routes'
import StoreProvider from '../../store/StoreProvider'
import AppHeader from '../../components/AppHeader'

const App = () => {
  return (
    <StoreProvider>
      <BrowserRouter>
        <Box textAlign="center">
          <AppHeader />
          <Routes />
        </Box>
      </BrowserRouter>
    </StoreProvider>
  )
}

export default App
