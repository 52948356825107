import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import logo from '../../assets/images/logo.svg'

const AppHeader = () => {
  return (
    <Header>
      <Logo src={logo} alt="logo" />
      <MenuLink to="/">Home</MenuLink>
      <MenuLink to="/login">Login</MenuLink>
      <MenuLink to="/dashboard">Dashboard</MenuLink>
      <MenuLink to="/todos">Todos</MenuLink>
    </Header>
  )
}

export default AppHeader

const Header = styled.header`
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Logo = styled.img`
  height: 10vmin;
  pointer-events: none;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${spin} infinite 20s linear;
  }
`

const MenuLink = styled(Link)<LinkProps>`
  color: #61dafb;
  margin-right: 20px;
`
