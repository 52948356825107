import styled from 'styled-components'
import { space, typography, SpaceProps, TypographyProps } from 'styled-system'

interface BoxProps extends SpaceProps, TypographyProps {}

const Box = styled.div<BoxProps>`
  ${space}
  ${typography}
`

export default Box
