import React from 'react'
import styled from 'styled-components'
import { Todo } from '../store/types'

type Props = {
  todos: Todo[]
  onCompleteToggle: (todo: Todo) => void
}

const TodosList = ({ todos, onCompleteToggle }: Props) => {
  return (
    <>
      {todos.map((todo) => (
        <TodoContainer key={todo.id}>
          <TodoText completed={todo.completed}>{todo.text}</TodoText>
          <input
            type="checkbox"
            checked={todo.completed}
            onChange={() => onCompleteToggle(todo)}
          />
        </TodoContainer>
      ))}
    </>
  )
}

export default TodosList

const TodoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 5px;
`

const TodoText = styled.div<{
  completed: boolean
}>`
  max-width: 200px;
  text-align: left;
  text-decoration: ${(p) => p.completed && 'line-through'};
`
